import {React,useEffect} from 'react'
import "../components/NewsFeed.css";
import onam from '../images/award.jpg'
import retfest from '../images/retfest.jpg'
import happy2025 from '../images/icons/happy2025.png'
import mkrishna from '../images/icons/mkrishna.jpg'
import support from '../images/support.jpg'


import VideoPlayer from './VideoPlayer';
import videosrc from '../video/mawanews.mp4';
import posterimg from '../video/mawanews.png';

import AOS from 'aos';
import 'aos/dist/aos.css';
import HomeVideo from './HomeVideo';

const NewsFeed = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true,     // Animation only happens once
    });
  }, []);
  return (
   <div data-aos="zoom-in">
    <h5 className='bg-secondary'>UPDATES..</h5>
    <div className='div-container'>

<div className='nfeed'>
    <h6></h6>
<div
><h6 className='nhead'></h6>
</div>

<div>
<h6 className='nhead'>ഉപഹാരം</h6>
    <img className='img-class2' src={mkrishna}></img>

    <h7>പ്രവാസ ജീവിതം മതിയാക്കി നാട്ടിലേക്ക് മടങ്ങുന്ന മഞ്ചേരി വെൽഫയർ ഉപദേശകാംഗമായ മുരളി കൃഷ്ണൻ എന്നിവർക്ക് അബ്ദുൽ റസാക്ക് NT എന്ന കുഞ്ഞിപ്പ ഉപഹാരം നൽകുന്നു</h7>
</div>
<hr  style={{
    color: '#000000',
    backgroundColor: '#000000',
    height: .5,
    borderColor : '#000000' 
}}/>
<div></div>

<div>
<h6 className='nhead'>അവാർഡ് ദാനം </h6>
    <img className='img-class2' src={onam}></img>

    <h7>മഞ്ചേരി വെൽഫെയർ അസോസിയേഷൻ മെമ്പർമാരുടെ കുട്ടികൾക്കുള്ള പ്ലസ്ടു, എസ് എസ്  എൽ  സി വിജയികൾക്കുള്ള അവാർഡ് ദാനം റിയാദിൽ വെച്ച് നടന്നു.</h7>
</div>
<hr  style={{
    color: '#000000',
    backgroundColor: '#000000',
    height: .5,
    borderColor : '#000000' 
}}/>



</div>

<div className='levents'>
      <h5>MAWA NEWS</h5>
      <VideoPlayer className='div-video' 
      src={videosrc} postersrc={posterimg}
      
      width="720" 
      height="405" 
    />
  <img className='img-eve2' src={happy2025}></img>
 

</div>


<hr  style={{
    color: '#000000',
    backgroundColor: '#000000',
    height: .5,
    borderColor : '#000000' 
}}/>
</div>
    </div>
    
  )
}

export default NewsFeed