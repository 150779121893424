import React from 'react'; 
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainRouter from "./components/MainRouter"
import Header from './components/Header';
import MyNavigator from './components/MyNavigator';
import 'lightbox.js-react/dist/index.css'
import 'font-awesome/css/font-awesome.min.css';






function App() {
  return (
    <>

<MyNavigator/>
     <MainRouter/>
     
    
      
   


        </>
  );
}

export default App;
