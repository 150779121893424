import {React,useState,useEffect} from 'react'
import {Container, Nav, Navbar} from 'react-bootstrap';
import "../components/MyNavigator.css";
import { Link } from 'react-router-dom';
import logoimg from "../images/logonew.jpg";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const Modal = ({ show, handleClose, handleLogin }) => {
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  
  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin(userId, password);
  };

  if (!show) return null; // Don't render the modal if show is false

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>User ID: </label>
            <input
              type="text"
              placeholder='User ID'
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Password: </label>
            <input
              type="password"
            placeholder='Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Login</button>
        </form>
        <button onClick={handleClose}>Close</button>
      </div>
    </div>
  );
};


const MyNavigator = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const cookie = Cookies.get('myCookie');
    const cookie2 = Cookies.get('myCookie2');
    if (cookie==='admin' && cookie2==='321') {
      setIsLoggedIn(true); // If cookie exists, user is logged in
    }
  }, []);

  const handleLogin = (userId, password) => {
    // Simulate login with userId and password
    Cookies.set('myCookie', `${userId}`, { expires: 7 }); // Store a cookie with userId and token
    Cookies.set('myCookie2', `${password}`, { expires: 7 }); // Store a cookie with userId and token
    const cookie = Cookies.get('myCookie');
    const cookie2 = Cookies.get('myCookie2');
    if (cookie==='admin' && cookie2==='321') {

    setIsLoggedIn(true);
    }
    else{
      alert('Invalid Login Credentials');
    }
    setShowModal(false); // Close the modal after login
  };

  const handleLogout = () => {
    const confirmAction = window.confirm("Confirm Logout?");
    if (confirmAction) { 
       Cookies.remove('myCookie'); // Remove the auth token
    Cookies.remove('myCookie2'); // Remove the auth token
    setIsLoggedIn(false); // Update login state
    navigate('/');
    }
  };

  return (
    <div className='div-nav'>
      
      <Link as={Link} to="/">
        <img className='logo2' src={logoimg}/>
        </Link>
<Navbar expand="lg" className="bg-tertiary">
      <Container className='nav-cont bg-tertiary'>
      
       
        <Navbar.Brand href="#home"></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav id="m-color" className="me-auto bg-tertiary">
            <Nav.Link as={Link} to="/" className='navi-link' href=""><h5 id='m-text'>HOME</h5></Nav.Link>
            <Nav.Link as={Link} to="/services" className='navi-link' href="./services"><h5 id='m-text'>SERVICES</h5></Nav.Link>
            <Nav.Link as={Link} to="/gallery" className='navi-link' href="#link"><h5 id='m-text'>GALLERY</h5></Nav.Link>
            <Nav.Link as={Link} to="/events" className='navi-link' href="#home"><h5 id='m-text'>EVENTS</h5></Nav.Link>
            <Nav.Link as={Link} to="/committee" className='navi-link' href="#link"><h5 id='m-text'>TEAM-MWA</h5></Nav.Link>
            <Nav.Link as={Link} to="/contacts" className='navi-link' href="#link"><h5 id='m-text'>CONTACT</h5></Nav.Link>
            {isLoggedIn ? (
            <Nav.Link as={Link} to="/dashboard" className='navi-link' href="#link"><h5 id='m-text'>Dashboard</h5></Nav.Link>
            
            ):(
              ""
            )}
            
          </Nav>
        </Navbar.Collapse>
      </Container>

     

    </Navbar>
    <div style={{ padding: '20px' }}>
      <h1>{isLoggedIn ? '' : ''}</h1>

      {isLoggedIn ? (
        <button className="login-button" onClick={handleLogout}>Logout</button>
      ) : (
        <button className="login-button" onClick={() => setShowModal(true)}>Login</button>
      )}

      <Modal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleLogin={handleLogin}
      />
    </div>

    </div>
  )
}

export default MyNavigator