import React,{useState} from 'react';
import "./DisplayImages.css"
import {SlideshowLightbox} from 'lightbox.js-react'

const importAll = (r) => r.keys().map(r);

const DisplayImages = (props) => {

  const getImages = () => {
    switch (props.folder) {
    case 'monchmanjeri':
      return importAll(require.context(`../images/monchmanjeri/`, false, /\.(png|jpe?g|svg)$/));
      break;
    case 'iftar2024':
      return  importAll(require.context(`../images/iftar2024/`, false, /\.(png|jpe?g|svg)$/));
      break;
      case 'onam2023':
      return  importAll(require.context(`../images/onam2023/`, false, /\.(png|jpe?g|svg)$/));
      break;
      case 'eidtour2024':
      return  importAll(require.context(`../images/eidtour2024/`, false, /\.(png|jpe?g|svg)$/));
      break;
      case 'retfest2024':
      return  importAll(require.context(`../images/retfest2024/`, false, /\.(png|jpe?g|svg)$/));
      break;
      case 'social':
      return  importAll(require.context(`../images/social/`, false, /\.(png|jpe?g|svg)$/));
      break;
      case 'events':
      return  importAll(require.context(`../images/events/`, false, /\.(png|jpe?g|svg)$/));
      break;
      case 'education':
      return  importAll(require.context(`../images/education/`, false, /\.(png|jpe?g|svg)$/));
      break;
      case 'business':
      return  importAll(require.context(`../images/business/`, false, /\.(png|jpe?g|svg)$/));
      break;
      case 'maingallery':
      return  importAll(require.context(`../images/maingallery/`, false, /\.(png|jpe?g|svg)$/));
      break;
      default:
       }
      };
      const images = getImages(); // Call the function to get images
  
     return (
      <div>
        <h4 className='bg-danger'>{props.progtitle}</h4>
    <div className='div-img-gal'>
    <SlideshowLightbox className='sb-container' showThumbnails={true}>
      {images.map((image, index) => (
        <img key={index} width="240" height="135" src={image} alt={`Image ${index + 1}`} />
        ))}
        </SlideshowLightbox> 
        
        </div>
        </div>
  );
}

export default DisplayImages;
