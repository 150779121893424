import React from 'react'
import { Tab, Tabs } from "react-bootstrap";
import Committee from './Committee';
import Details from './Details';
import ContactUs from './ContactUs';
import ImageGallery from './ImageGallery';
import "../components/MyGallery.css";
import VideoGallery from './VideoGallery';
import DisplayImages from './DisplayImages';

const MyGallery = () => {
  return (
    <div>
    <div  className='div-body'>

<Tabs className='div-tab'
      defaultActiveKey="home"
      id="uncontrolled-tab-example"
         >
      <Tab eventKey="home" title="IMAGE GALLERY">
      <DisplayImages folder="maingallery"/>
      </Tab>
      <Tab eventKey="profile" title="VIDEO GALLERY">
        <VideoGallery/>

      </Tab>
      
    </Tabs>
    </div>
    </div>
  )
}

export default MyGallery